import React, { useState } from 'react'
import {
  Div,
  Flex,
  QuantumThemeWrapper,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import {
  GlassBackgroundContainer,
  GlassBlobType,
} from 'src/pricing_page/components/GlassBackground'
import { AutomateBentoCards } from '../mockData/BentoCards/Automate/AutomateBentoCards'
import {
  BannerSlider,
  BannerSlideType,
} from 'src/home_page/components/BannerSlider/BannerSlider'
import { BentoContainer } from '../components/BentoGrid/BentoContainer'
import { BentoGridContainer } from '../components/BentoGrid/BentoGrid'
import { Button } from 'src/design_system/components/Button'
import { CardCategorySlider } from 'src/home_page/components/CardCategorySlider/CardCategorySlider'
import { CardOptionsBentoCards } from '../mockData/BentoCards/CardOptions/CardOptionsBentoCards'
import { cardStoriesMockData } from '../mockData/CardStoriesRibbonMockData'
import { CardStoriesRibbon } from 'src/home_page/components/CardStoriesRibbon/CardStoriesRibbon'
import { CardTypeBentoCards } from '../mockData/BentoCards/CardType/CardTypeBentoCards'
import { CardTypeImageDiffData } from '../mockData/CardTypeImageDiffData'
import { ContentBlock } from 'src/home_page/components/ContentBlock/ContentBlock'
import { CustomizationOptionsBentoCards } from '../mockData/BentoCards/CustomizationOptions/CustomizationOptionsBentoCards'
import { DynamicImageDiff } from '../components/DynamicImageDiff/DynamicImageDiff'
import { GiftBentoCards } from '../mockData/BentoCards/Gift/GiftBentoCards'
import { HomePageBanner } from 'src/home_page/components/HomePageBanner/HomePageBanner'
import { useWindowWidth } from '@react-hook/window-size'
import { performOperation } from 'src/app/api'
import {
  CreateSparseUser,
  CreateSparseUserData,
  InviteFragment,
} from 'src/graphql'

import { Transition } from 'src/chrome'
import { openApp } from 'src/helpers/appHelpers'
import {
  CustomComponentSystemBentoCards,
  SystemBentoGridCards,
} from '../mockData/BentoCards/System/SystemBentoCards'
import { VideoPlayer } from 'src/home_page/components/VideoPlayer/VideoPlayer'
import useSelector from 'src/hooks/useSelector'
import { CustomerReview } from 'src/home_page/components/ConsumerReviews/components/CustomerReview'

type LaunchPageType = { invite?: InviteFragment }
// TODO: Hook up ProductPopUp to prod data

const blobs: GlassBlobType[] = [
  {
    size: '30%',
    shape: 'circle',
    filter: 'blur(100px)',
    offset: {
      top: '10%',
      left: '-35%',
    },
    // Pink Gradient
    gradient:
      'linear-gradient(to bottom right,  #EC4899 100%, #D946EE 100%) bottom left / 50% 50% no-repeat',
  },
  {
    size: '40%',
    shape: 'circle',
    filter: 'blur(100px)',
    offset: {
      top: '-30%',
      left: '-10%',
    },
    // Orange Gradient
    gradient:
      'linear-gradient(to bottom left, #EC4898 100%, #F59E10 100%) bottom left / 50% 50% no-repeat',
  },
  {
    size: '40%',
    shape: 'circle',
    filter: 'blur(70px)',
    offset: {
      top: '20%',
      left: '-10%',
    },
    // Yellow
    gradient:
      'linear-gradient(to bottom left, #FDE588 100%, #FACC1B 100%) bottom left / 50% 50% no-repeat',
  },
  {
    size: '40%',
    shape: 'circle',
    filter: 'blur(50px)',
    offset: {
      top: '5%',
      left: '40%',
    },
    // Orange
    gradient:
      'linear-gradient(to bottom left, #EC4898 100%, #F59E10 100%) bottom left / 50% 50% no-repeat',
  },
  {
    size: '40%',
    shape: 'circle',
    filter: 'blur(70px)',
    offset: {
      top: '-35%',
      left: '30%',
    },
    // Yellow
    gradient:
      'linear-gradient(to bottom left, #FDE588 100%, #FACC1B 100%) bottom left / 50% 50% no-repeat',
  },
  {
    size: '40%',
    shape: 'circle',
    filter: 'blur(50px)',
    offset: {
      top: '5%',
      left: '40%',
    },
    // Lavender
    gradient:
      'linear-gradient(to bottom left, #DDD6FD 100%, #FACFE8 100%) bottom left / 50% 50% no-repeat',
  },
]

// tslint:disable-next-line: cyclomatic-complexity score 25
const LaunchPage: React.FC<LaunchPageType> = props => {
  const state = useSelector(state => state)
  const windowWidth = useWindowWidth()
  const isSmallMobile = windowWidth <= 500
  const isMobile = windowWidth <= 1180
  const isSystemSectionMobileBreakpoint = windowWidth <= 700
  const [activeCardTypeBentoId, setActiveCardTypeBentoId] = useState(0)
  const [isCreatingAccount, setIsCreatingAccount] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const sponsorId = state.storefront.storefront?.user.genealogyId.toString()
  const open = (href: string) => () => {
    window.location.href = href
  }
  const goToApp = (query: { [key: string]: string } = {}) => {
    setIsRedirecting(true)
    openApp(``, query)
  }
  const { invite } = props
  const handleSendFreeCard = () => {
    if (invite) {
      setIsCreatingAccount(true)
      performOperation<CreateSparseUserData>(
        CreateSparseUser({ inviteId: invite.id }),
        result => {
          setIsCreatingAccount(false)
          if (result.value) {
            goToApp({
              access_token: result.value.createSparseUser.account.token,
              postcardPromo: '',
            })
          } else {
            goToApp({ verify: '', postcardPromo: '' })
          }
        },
      )
    } else {
      goToApp({ verify: '', postcardPromo: '' })
    }
  }

  if (isCreatingAccount || isRedirecting) {
    return (
      <Transition
        message={isRedirecting ? 'Loading...' : 'Creating guest account...'}
        messageStyle={{ color: 'black', fontWeight: 500 }}
      />
    )
  }
  const bannerSlides: BannerSlideType[] = [
    {
      title: 'Automate your gratitude',
      subtitle: 'and take your appreciation efforts to a whole new level.',
      imageUrl:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/home-banners/Automated-image-16-9.png',
      action: {
        title: 'View Our Packages',
        onClick: open('https://www.sendoutcards.com/app/pricing#packages'),
      },
      themeBackgroundColor: '#d0e0e3',
      themeColor: '#134f5c',
      titleColorOverride: '#134f5c',
    },
    {
      title: 'ACT on Promptings',
      subtitle: 'Change lives with the premier gratitude sharing service.',
      imageUrl:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/banner-love-card.png',
      action: {
        title: 'Send a Card',
        onClick: () => handleSendFreeCard(),
      },
      titleColorOverride: '#AC61BA',
      buttonColorOverride: '#404040',
      themeBackgroundColor: '#DFBDD6',
    },
    {
      title: 'Thankful Hearts, Grateful Moments',

      subtitle:
        'Celebrate the season of gratitude by sharing heartfelt cards that bring warmth and connection to those you cherish most.',
      imageUrl:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/Grateful-Thanksgiving-carousel-image-16-9.png',
      action: {
        title: 'Shop Now',
        onClick: open('https://app.sendoutcards.com/catalog/collections/163'),
      },
      themeBackgroundColor: '#e2c098',
      themeColor: '#c69861',
    },
    {
      title: 'Heartfelt Connections, Cherished Moments',
      subtitle:
        'Celebrate the season of love by sending thoughtful cards that show how much you care and bring joy to the ones whou hold a special place in your heart',
      imageUrl:
        'https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/Valentines-carousel-image-16-9.png',
      action: {
        title: 'Shop Now',
        onClick: open('https://app.sendoutcards.com/catalog/collections/166'),
      },
      themeBackgroundColor: '#b84091',
      themeColor: '#f8d6ed',
    },
  ]

  return (
    <QuantumThemeWrapper>
      <Flex
        flexDirection="column"
        alignItems="center"
        backgroundColor="rgb(249, 250, 251)"
        position="relative"
        rowGap="x4"
        width="100%"
        overflowX="hidden"
      >
        {/* Top Banner */}
        <Div
          position={'absolute'}
          style={{
            background:
              'linear-gradient(180deg, #FFFFFF 0%, #F7F2F9 50%, #EBE5F0 100%)',
            backdropFilter: 'blur(200px)',
          }}
        >
          <HomePageBanner />
        </Div>
        <Spacer orientation="vertical" space="x4" />
        <BannerSlider slides={bannerSlides} />
        {/* Top Banner */}
        <CardStoriesRibbon stories={cardStoriesMockData} />
        {/* Section Divider Text */}
        <Flex width="100%" justifyContent="center" alignItems="center">
          <Text
            inset={{
              top: '32px',
            }}
            type="title"
            weight="semiBold"
            content="From your heart to their mailbox"
            alignment="center"
          />
        </Flex>
        <Flex
          id="bento_sections_container"
          flexDirection="column"
          inset={{
            horizontal:
              windowWidth <= 530 ? 'x2' : windowWidth <= 800 ? 'x4' : 'x5',
          }}
          position="relative"
          rowGap="x4"
          width="100%"
        >
          {/* Card Type Section */}
          <Flex width="100%" height="100%" inset={{ bottom: 'x4' }}>
            <BentoGridContainer
              blobs={{
                option: 3,
                width: '80%',
                height: 'auto',
                position: { bottom: '0px' },
                glassBlur: 50,
              }}
              isFlexOverride={isMobile}
              gridRowOverride={'repeat(5 1fr)'}
              gridColumnOverride={'repeat(5 1fr)'}
              maxHeight="auto"
              cards={CardTypeBentoCards(isMobile)}
              isMobile={isMobile}
              gridTexts={{
                title: 'ACT on promptings guided by your heart.',
                description:
                  'Our beautiful catalog cards quickly help you act on every prompting. You can choose from our pre-designed Catalog Cards, Photo Drop (add your photos onto design), or customize Build Your Own.',
                gridArea: {
                  colStart: '3',
                  colEnd: '6',
                  rowStart: '1',
                  rowEnd: '4',
                },
              }}
              activeBentoCallback={(id: number) => setActiveCardTypeBentoId(id)}
              childrenGridArea={{
                colStart: '1',
                colEnd: '3',
                rowStart: '1',
                rowEnd: '6',
              }}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                height="100%"
                width="100%"
              >
                <Flex
                  width="100%"
                  justifyContent="center"
                  height="100%"
                  alignContent="center"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      backgroundColor: '#ffffff99',
                      borderRadius: '1.25rem',
                      backdropFilter: 'blur(75px)',
                    }}
                    boxShadow="light"
                    borderRadius="default"
                    width={
                      windowWidth >= 520 && windowWidth <= 605
                        ? '70%'
                        : windowWidth >= 606 && windowWidth <= 800
                        ? '60%'
                        : windowWidth >= 801 && windowWidth <= 1180
                        ? '55%'
                        : '100%'
                    }
                    minWidth={isMobile ? undefined : '400px'}
                    inset={'x4'}
                  >
                    <DynamicImageDiff
                      images={CardTypeImageDiffData}
                      activeImageIndex={activeCardTypeBentoId}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </BentoGridContainer>
          </Flex>
          {/* Card and Paper Options Section */}
          {isMobile && (
            <Flex width="100%" justifyContent="center" alignItems="center">
              <Text
                inset={{
                  top: '32px',
                  bottom: '32px',
                }}
                type="title"
                weight="semiBold"
                content="Card and paper options for any occasion."
                alignment="center"
              />
            </Flex>
          )}
          <BentoGridContainer
            isFlexOverride={isMobile}
            gridRowOverride={'repeat(5 1fr)'}
            cards={CardOptionsBentoCards(isMobile)}
            isMobile={isMobile}
            gridTexts={{
              title: isMobile ? '' : 'Card and paper options for any occasion.',
              gridArea: {
                colStart: '1',
                colEnd: isMobile ? '21' : '12',
                rowStart: '1',
                rowEnd: '2',
              },
            }}
            isComponentRight={true}
            childrenGridArea={{
              colStart: isMobile ? '1' : '12',
              colEnd: '21',
              rowStart: '1',
              rowEnd: '6',
            }}
            maxHeight="auto"
            blobs={{
              option: 3,
              width: '80%',
              height: 'auto',
              position: { bottom: '0px' },
              glassBlur: 90,
            }}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              height="100% width='100%"
            >
              <CardCategorySlider />
            </Flex>
          </BentoGridContainer>
          {/* Gift Section */}
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            inset={{
              top: '32px',
            }}
          >
            <Text
              type="title"
              weight="semiBold"
              content="Add a gift and create an experience"
              alignment="center"
            />
          </Flex>
          <BentoGridContainer
            gridRowOverride={'repeat(5 1fr)'}
            gridColumnOverride={'repeat(5 1fr)'}
            maxHeight="auto"
            isFlexOverride={isMobile}
            cards={GiftBentoCards(isMobile)}
            isMobile={isMobile}
            gridTexts={{
              title: 'Enhance your prompting with something sweet.',
              description:
                "We make it easy to attach a gift to your greeting card, anytime, anywhere. We've got the perfect gift for any and every occasion.",
              gridArea: {
                colStart: '3',
                colEnd: '6',
                rowStart: '1',
                rowEnd: '4',
              },
            }}
            childrenGridArea={{
              colStart: '1',
              colEnd: '3',
              rowStart: '1',
              rowEnd: '6',
            }}
            blobs={{
              option: 4,
              width: '80%',
              height: 'auto',
              position: { bottom: '0px' },
              glassBlur: 130,
            }}
          >
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <BentoContainer
                isActive={false}
                onClick={() => {}}
                blobs={{
                  option: 4,
                  width: '80%',
                  height: '100%',
                  glassBlur: 70,
                }}
                width="100%"
              >
                <Flex
                  minHeight="300px"
                  minWidth="300px"
                  width="100%"
                  justifyContent="center"
                  height="auto"
                  borderRadius="default"
                  alignItems="center"
                  backgroundSize="contain"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundImage="https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/brownies-carmels-no-background.png"
                />
              </BentoContainer>
            </Flex>
          </BentoGridContainer>
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            inset={{
              top: '32px',
            }}
          >
            <Text
              type="title"
              weight="semiBold"
              alignment="center"
              content="Take customization to the next level"
            />
          </Flex>
          {/* Customization Section */}
          <BentoGridContainer
            cards={CustomizationOptionsBentoCards(isMobile)}
            isMobile={isMobile}
            gridRowOverride="repeat(10 1fr)"
            gridColumnOverride="repeat(10 1fr)"
            isFlexOverride={isMobile}
            maxHeight="auto"
            childrenGridArea={{
              colStart: '3',
              colEnd: '10',
              rowStart: '1',
              rowEnd: '10',
            }}
            gridTexts={{
              title: 'Creative card editor and customization.',
              description:
                'From the front of the card to the back panel, you can completely customize your card for a seamless experience. Add your own handwriting font, create consistency with colors, add photos, or your own custom branding!',
              gridArea: {
                colStart: '1',
                colEnd: '3',
                rowStart: '1',
                rowEnd: '3',
              },
            }}
            blobs={{
              option: 1,
              width: '60%',
              height: 'auto',
              position: { bottom: '0px' },
              glassBlur: 70,
            }}
          >
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              borderRadius="default"
            >
              <BentoContainer
                isActive={false}
                onClick={() => {}}
                blobs={{
                  option: 1,
                  width: '95%',
                  height: 'auto',
                  glassBlur: 65,
                }}
                width="100%"
                height="auto"
              >
                <Flex
                  outset={{ left: isMobile ? undefined : '-80px' }}
                  width="100%"
                  minHeight="300px"
                  minWidth="600px"
                  justifyContent="center"
                  maxHeight="500px"
                  height="100%"
                  alignItems="center"
                  backgroundSize={isMobile ? 'contain' : 'cover'}
                  backgroundPosition={isMobile ? 'center' : 'right center'}
                  backgroundRepeat="no-repeat"
                  backgroundImage="https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/editor-animation.gif"
                />
              </BentoContainer>
            </Flex>
          </BentoGridContainer>
          {/* System Section */}
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            inset={{
              top: '32px',
            }}
          >
            <Text
              type="title"
              weight="semiBold"
              content="A system to remind you to stay in touch"
              alignment="center"
            />
          </Flex>
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection={
              windowWidth <= 1180 ? 'column-reverse' : 'row-reverse'
            }
          >
            <Flex
              width="100%"
              justifyContent="center"
              alignItems="center"
              rowGap="32px"
              columnGap="32px"
              flexDirection={windowWidth >= 1180 ? 'row' : 'column'}
            >
              <Flex
                flexDirection={windowWidth >= 1300 ? 'column' : 'row'}
                maxWidth={windowWidth >= 1180 ? '650px' : undefined}
                width="100%"
              >
                <BentoGridContainer
                  gridRowOverride="repeat(n, minmax(auto, 1fr))"
                  gridColumnOverride="1fr 1fr"
                  maxHeight="auto"
                  isFlexOverride={isSystemSectionMobileBreakpoint}
                  cards={CustomComponentSystemBentoCards(
                    isMobile,
                    isSystemSectionMobileBreakpoint,
                  )}
                  childrenGridArea={{
                    colStart: '1',
                    colEnd: '2',
                    rowStart: '1',
                    rowEnd: '3',
                  }}
                  blobs={{
                    option: 3,
                    width: '80%',
                    height: 'auto',
                    position: { bottom: '0px' },
                    glassBlur: 70,
                  }}
                >
                  <Flex
                    width="100%"
                    justifyContent="center"
                    height="100%"
                    alignItems="center"
                    minHeight="400px"
                  >
                    <BentoContainer
                      isActive={false}
                      width="100%"
                      onClick={() => {}}
                      blobs={{
                        option: 3,
                        width: '100%',
                        height: 'auto',
                        glassBlur: 70,
                      }}
                    >
                      <Flex
                        width="100%"
                        justifyContent="center"
                        height="100%"
                        minHeight="300px"
                        minWidth="300px"
                        alignItems="center"
                        backgroundSize="contain"
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundImage="https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/Calendar-reminders.png"
                      />
                    </BentoContainer>
                  </Flex>
                </BentoGridContainer>
              </Flex>
              <Flex
                maxWidth={windowWidth >= 1300 ? '650px' : undefined}
                minHeight="400px"
                justifyContent="space-between"
                alignItems="space-between"
                height="auto"
              >
                <BentoGridContainer
                  isFlexOverride={isSystemSectionMobileBreakpoint}
                  gridRowOverride="repeat(n, minmax(auto, 1fr))"
                  gridColumnOverride="1fr 1fr"
                  textHeightOverride={!isMobile ? '180px' : undefined}
                  textWidthOverride={!isMobile ? '480px' : undefined}
                  cards={SystemBentoGridCards(
                    isMobile,
                    isSystemSectionMobileBreakpoint,
                  )}
                  gridTexts={{
                    title: 'Helping you stay top of mind, on time, every time.',
                    description:
                      'We become your personal assistant to enhance human connection. We do this through timely reminders for upcoming birthdays, anniversaries, or throughout the year with our campaigns.',
                    gridArea: {
                      colStart: '1',
                      colEnd: '4',
                      rowStart: '1',
                      rowEnd: '2',
                    },
                  }}
                  blobs={{
                    option: 3,
                    width: '80%',
                    height: 'auto',
                    position: { bottom: '0px' },
                    glassBlur: 70,
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          {/* Automation Section */}
          <Flex
            width="100%"
            justifyContent="center"
            alignItems="center"
            inset={{
              top: 'x3',
            }}
          >
            <Text
              type="title"
              weight="semiBold"
              content="Time getting away from you? Automate your sending."
              alignment="center"
            />
          </Flex>
          <BentoGridContainer
            isFlexOverride={isMobile}
            maxHeight="auto"
            gridRowOverride="repeat(4 fr)"
            cards={AutomateBentoCards(isMobile)}
            isMobile={isMobile}
            childrenGridArea={{
              colStart: isMobile ? '1' : '13',
              colEnd: '21',
              rowStart: '2',
              rowEnd: '4',
            }}
            gridTexts={{
              title: 'Let us ease your concern and get it done for you.',
              description:
                'Our packages provide plug-and-play options based on your needs. For our business owners, business entrepreneurs, or busy bodies, we can automate your card sending to follow up and stay top of mind with your network.',
              gridArea: {
                colStart: isMobile ? '1' : '13',
                colEnd: '21',
                rowStart: '1',
                rowEnd: '2',
              },
            }}
            blobs={{
              option: 4,
              width: '70%',
              height: 'auto',
              position: { bottom: '0px' },
              glassBlur: 120,
            }}
            isComponentRight={true}
          >
            <Flex
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <BentoContainer
                isActive={false}
                onClick={() => {}}
                blobs={{
                  option: 4,
                  width: '100%',
                  height: '100%',
                  glassBlur: 85,
                }}
                width="100%"
              >
                <Flex
                  minHeight="200px"
                  width="100%"
                  justifyContent="center"
                  height="inherit"
                  alignItems="center"
                  backgroundSize="contain"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundImage="https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/john-doe.png"
                />
              </BentoContainer>
            </Flex>
          </BentoGridContainer>
        </Flex>
        <Flex width="100%" justifyContent="center" inset="x4">
          <Button
            title="View our packages"
            backgroundColor={'#fff'}
            textColor={'#404040'}
            onClick={open(`https://www.sendoutcards.com/app/pricing/`)}
            padding="1rem 1.25rem"
          />
        </Flex>
        <GlassBackgroundContainer blobStart="center" blobs={blobs}>
          <Flex
            width="100%"
            inset={
              isSmallMobile
                ? { horizontal: '1.5rem', vertical: '4rem' }
                : '4rem'
            }
            alignItems="center"
            justifyContent="center"
            flexDirection={windowWidth <= 1024 ? 'column' : 'row'}
            columnGap="x5"
            rowGap="4rem"
          >
            <VideoPlayer
              thumbnailUrl="https://soc-website-public.s3.us-west-2.amazonaws.com/static/soc-website/images/ian-poster.png"
              playbackOption={{
                iframeSrc:
                  'https://fast.wistia.net/embed/iframe/npexmwps8c?autoplay=1&mute=1',
                iframeTitle: 'tilted',
              }}
              width={windowWidth <= 700 ? '100%' : '650px'}
              btnColor="#fff"
              height="400px"
            />
            <ContentBlock
              title="We are the premier gratitude sharing service"
              description="Our beautiful catalog cards quickly help you act on every promptings. You can choose from our pre-designed Catalog Cards, Photo Drop (Add your photos onto design), or customize Build Your Own (from scratch)"
              primaryAction={{
                title: 'Send a Card',
                onClick: () => handleSendFreeCard(),
              }}
              width={windowWidth <= 1024 ? '100%' : '406px'}
            />
          </Flex>
        </GlassBackgroundContainer>
        <Flex
          flexDirection="column"
          justifyContent="center"
          width="100%"
          inset="2rem"
        >
          <Text
            type="body"
            alignment="center"
            content="A trusted household name - see what others are saying"
            style={{
              fontFamily: 'Montserrat',
              fontSize: '1.875rem',
              fontWeight: 700,
              color: '#404040',
            }}
          />
          <Flex
            width="100%"
            inset={{
              vertical: 'x6',
            }}
          >
            <CustomerReview />
          </Flex>
        </Flex>
        <Flex
          width="100%"
          inset={
            isSmallMobile ? { horizontal: '1.5rem', vertical: '4rem' } : '4rem'
          }
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgba(0, 0, 0, 0.01)"
          flexDirection={windowWidth <= 1024 ? 'column' : 'row'}
          columnGap="x5"
          rowGap="4rem"
        >
          <ContentBlock
            title="The Gratitude Movement"
            subtitle={{
              subtitle: 'Be. Share. Show.',
              color: '#09E0E4',
            }}
            description="Join a community dedicated to being in gratitude, sharing gratitude with others, and showing others how to be in and share gratitude.   Discover the next massive movement that is creating authentic success and bringing people back together again."
            width={windowWidth <= 1024 ? '100%' : '515px'}
            primaryAction={{
              title: 'Learn More',
              bgColor: '#fff',
              textColor: '#000',
              onClick: () =>
                window.open(
                  `https://www.promptings.com/${
                    sponsorId ? `?sponsor=${sponsorId}` : ''
                  }`,
                ),
            }}
            shouldHideShadow={true}
          />
          <Flex
            backgroundColor="black"
            borderRadius="30px"
            outset={windowWidth <= 640 ? '20px' : undefined}
          >
            <VideoPlayer
              thumbnailUrl="https://i.vimeocdn.com/video/1950234241-3ceca44f1198164ec367034abd0027d6fbf732cbf0dc9ef2222712cc75b6b802-d?mw=2900&mh=1631&q=70"
              playbackOption={{
                iframeSrc:
                  'https://player.vimeo.com/video/1025632463?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
                iframeTitle: 'tilted',
              }}
              width={windowWidth <= 640 ? '75vw' : '514px'}
              btnColor="#fff"
              height={windowWidth >= 640 ? '284px' : '220px'}
            />
          </Flex>
        </Flex>
      </Flex>
    </QuantumThemeWrapper>
  )
}

export default LaunchPage
